.navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0; }
  .navigation .logo a span {
    background: rgb(var(--background-color));
    margin-left: 5px;
    padding: 2px 5px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 1.8rem; }
  .navigation .nav-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }

.ufg-main-container {
  background: rgb(var(--background-color));
}
.ugf-form .input-block + .input-block {
  margin-top: 50px; }

.ugf-form .input-block h4 {
  font-size: 2.4rem;
  font-weight: 700;
  color: rgb(var(--font-primary)); }
  .ugf-form .input-block h4 + div {
    margin-top: 40px; }

.ugf-form .input-block p {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--font-primary-50);
  margin-top: 5px; }
  .ugf-form .input-block p + div {
    margin-top: 40px; }

.ugf-form .input-block .form-group {
  margin-bottom: 5px;
  width: 100%; }
  .ugf-form .input-block .form-group label {
    font-size: 1.2rem;
    font-weight: 700;
    color: rgb(var(--font-primary));
    text-transform: uppercase; }
  .ugf-form .input-block .form-group .form-control {
    height: 40px;
    border-radius: 8px;
    border: 0.5px solid var(--font-primary-50);
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    background: white;
    padding: 0 20px;
    font-size: 12px;
    color: var(--font-primary-50); }
    .ugf-form .input-block .form-group .form-control::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--font-primary-50); }
    .ugf-form .input-block .form-group .form-control::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--font-primary-50); }
    .ugf-form .input-block .form-group .form-control:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--font-primary-50); }
    .ugf-form .input-block .form-group .form-control:-moz-placeholder {
      /* Firefox 18- */
      color: var(--font-primary-50); }
  .ugf-form .input-block .form-group .select-input {
    position: relative; }
    .ugf-form .input-block .form-group .select-input span {
      position: absolute;
      top: 42px;
      right: 15px;
      height: 0;
      width: 0;
      border-top: 6px solid var(--font-primary-50);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent; }
    .ugf-form .input-block .form-group .select-input .form-control {
      width: 100%;
      padding-right: 0;
      padding-left: 15px;
      color: var(--font-primary-50);
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none; }
      .ugf-form .input-block .form-group .select-input .form-control option {
        color: var(--font-primary-50); }
.ugf-form .btn {
  height: 40px;
  width: 100%;
  line-height: 40px;
  padding: 0;
  border-radius: 8px;
  background: rgb(var(--primary));
  color: white;
  font-size: 1.5rem;
  font-weight: 100;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none; }
@media all and (max-width: 767px) {
  .navigation .logo img {
    height: 35px; }
}

@media all and (max-width: 500px) {
}

@media all and (max-width: 439px) {
  
}

.ugf-form .input-block .custom-future-use {
  margin-left: 20px;
  margin-right: 50rem;
  min-width: 156px;
  font-size: 10px;
}
  .ugf-form .input-block .custom-future-use .future-use-label {
    margin-left: 10px;
  }

.col-md-12 .box-group {
  min-height:40px;
  background: var(--secondary-75);
  border-radius: 8px;
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  padding: 10px;
  align-content:center;
}
.col-md-12 .box-group .custom-tooltip {
  font-style: normal;
  font-weight: 200;
  font-size: 10px;
  color: rgb(var(--font-primary));
  display: inline-block;
  line-height: 14px;
}
.col-md-12 .box-group .custom-tooltip .bold {
  font-weight: 800;
}

.navigation .selector-holder {
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  background: rgb(var(--background-color));
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  height: 40px;
  list-style-type: none;
}

.selector-holder-btn {
  flex: 1 1 0 !important;
  min-width: 155px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: rgb(var(--font-primary));
  opacity: 0.4;
}

.card-types {
  float: right;
  margin-top: -26px;
  margin-right: 8px;
}

.col-md-12 .custom-pay-btn {
  box-shadow:0px 10px 30px -15px rgb(var(--primary));
  min-height:48px;
  cursor: pointer;
  background-color: rgb(var(--primary));
}

.container .row .form-shadow {
  background: white;
  border-radius: 8px;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
}

.circle {
  border-radius: 50%;
  border: solid 0px rgb(var(--font-secondary));
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  height:16px;
  background-color: rgb(var(--secondary));
  color: white;
  vertical-align: middle;
  line-height:normal;
  text-align: center;
  font-size:13px;
  margin-left: 5px;
  position: relative;
}

.btn-group .selected {
  background: rgb(var(--secondary));
  border-radius: 8px;
  min-width: 155px;
  max-height: 30px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: white !important;
  opacity: 1 !important;
}

.trans-holder {
  display:flex;
  justify-content: space-evenly;
  flex-direction:column;
}

.trans-form {
  font-weight:300;
  color: rgb(var(--font-primary));
  opacity: 0.5;
  margin-bottom:20px;
}

.required:before {
  content: "* ";
  color:#B7240B;
}

input[type=text] {
  border: 0.5px solid var(--font-primary-50) !important;
}

input[type=text]:focus {
  border: 1px solid rgb(var(--font-primary)) !important;
}

input[type=text].error {
  border: 1px solid #B7240B !important;
}
  select.error {
    border: 1px solid #B7240B !important;
  }

.form-group .error-message {
  font-size: 10px;
  line-height: 140%;
  color: #B7240B;
}

.form-group input, select {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  color: rgb(var(--font-primary)) !important;
}
  .form-group option {
    color: var(--font-primary-50);
  }

.form-group.terms-agreement {
  width: 100%;
  padding: 5px 10px;
}
  .form-group .custom-checkbox-box {
    margin-top: 10px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

.form-group .custom-checkbox {
  margin-top: 5px;
}

.form-group .terms-agreement-text, .disclaimer {
  font-weight: 300 !important;
  font-size: 10px !important;
  line-height: 140% !important;
  color: var(--font-primary-50) !important;
  text-transform: none !important;
}
  .form-group .terms-agreement-text {
    max-width: 90%;
  }
  .form-group .disclaimer {
    font-weight: 600 !important;
    margin-left: 4%;
    margin-bottom: 2%;
  }