html {
  height: 100%;
  background-color: rgb(var(--background-color));
}
.ugf-bg {
  background: rgb(var(--background-color)); }

.ugf-nav {
  background: rgb(var(--background-color));
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1; 
  min-height: 100px; }

.navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  height:100%; }
  .navigation .logo a span {
    background: rgb(var(--background-color));
    margin-left: 5px;
    padding: 2px 5px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 1.8rem; }

.navigation .logo img {
  max-width: 150px;
  max-height: 150px;
}
.ufg-main-container {
  padding-bottom: 50px;
  background: rgb(var(--background-color));
  overflow: hidden; 
}

.ugf-form .input-block + .input-block {
  margin-top: 40px; }

.ugf-form .input-block h4 {
  font-size: 2.0rem;
  font-weight: 700;
  font-family: "Readex Pro";
  color: rgb(var(--font-primary)); }
  .ugf-form .input-block h4 + div {
    margin-top: 15px; }

.ugf-form .input-block p {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Readex Pro";
  color: var(--font-primary-50);
  margin-top: 5px; }
  .ugf-form .input-block p + div {
    margin-top: 15px; }

.ugf-form .input-block .form-group {
  margin-bottom: 25px; }
  .ugf-form .input-block .form-group .form-control {
    height: 40px;
    border: 0.5px solid rgb(var(--font-primary));
    border-radius: 8px;
    background: white;
    padding: 0 10px;
    font-size: 12px;
    color: var(--font-primary-50); 
  }
    .ugf-form .input-block .form-group .form-control::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #b2becc; }
    .ugf-form .input-block .form-group .form-control::-moz-placeholder {
      /* Firefox 19+ */
      color: #b2becc; }
    .ugf-form .input-block .form-group .form-control:-ms-input-placeholder {
      /* IE 10+ */
      color: #b2becc; }
    .ugf-form .input-block .form-group .form-control:-moz-placeholder {
      /* Firefox 18- */
      color: #b2becc; }
  .ugf-form .input-block .form-group .custom-checkbox {
    margin-bottom: 10px; }
@media all and (max-width: 1199px) {
  
}

@media all and (max-width: 991px) {
  
}

@media all and (max-width: 767px) {
  .navigation .nav-btns .back {
    display: none; }
  .navigation .nav-btns .get {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 1.2rem; }
  }

@media all and (max-width: 500px) {
  .ugf-nav {
    height: unset !important;
    min-height: unset !important;
  }
  .ugf-nav .navigation {
    min-width: unset !important;
    height: unset !important;
    min-height: unset !important;
  }
  .navigation .nav-btns {
    flex-wrap: wrap;
    justify-content: space-evenly !important;
  }
    .navigation .logo, .navigation .logo-text {
      width: 100%;
      margin-bottom: 1rem;
    }
  .navigation .logo-text {
    font-size: 22px !important;
  }
  .navigation .nav-btns .prog-btn {
    min-width: min-content !important;
    width: auto !important;
    height: auto !important;
    gap: 1rem;
    padding: 1rem !important;
    align-self: stretch;
  }
    .navigation .nav-btns .prog-btn i {
      font-size: 24px;
    }
    .navigation .nav-btns .prog-btn .prog-icon {
      margin-right: 0 !important;
    }
  .ufg-main-container {
    padding-top: 2rem !important;
  }
  .widget-container {
    min-width: unset !important;
  }
  .ugf-form {
    min-width: unset !important;
  }
  .ugf-form label, .ugf-form input {
    font-size: 12px !important; 
  }
  form h4 {
    font-size: 16px !important;
  }
  .widget-holder {
    padding: 15px 10px !important;
  }
  .form-group .contact-radio-container {
    flex-wrap: wrap !important;
  }
    .form-group .contact-radio-container .choice-container {
      width: 45%;
    }
  .check-circle {
    width: 24px !important;
    height: 24px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .pay-choice-container {
    padding: 10px 10px !important;
  }
  .pay-calc-holder {
    flex-flow: row wrap !important;
    width: 100% !important;
    gap: 2rem;
  }
  .pay-calc-holder>div, .pay-calc-holder>a {
    width: 100% !important;
  }
  .pay-choices {
    width: 100% !important;
    flex-wrap: wrap !important;
    gap: 2rem !important;
  }
  .pay-choices .pay-box {
    width: 100% !important;
    margin: auto 0 0 0 !important;
  }
  .pay-box .choice-box.choice-card {
    margin-top: 0 !important;
  }
  .pay-choices .pay-box .amount-text {
    font-size: 10px !important;
  }
  .pay-choices .pay-box .choice-box {
    font-size: 10px !important;
  }
  .ugf-form .custom-pay-btn {
    margin-top: 2rem;
  }
  .form-group .trans-form {
    margin-bottom: 1rem !important;
  }
  .input-block .custom-future-use {
    border: none !important;
    padding: 0 !important;
    height: unset !important;
  }
  .navigation .selector-holder .selector-holder-btn {
    font-size: 12px !important;
  }
  .custom-form-container .pay-summary-box {
    flex-flow: column nowrap !important;
    gap: 2rem;
  }
    .custom-form-container .pay-summary-box .completed-info.column {
      flex-flow: row nowrap !important;
    }
      .custom-form-container .pay-summary-box .completed-info.column .info-text {
        font-size: 12px;
      }
    .custom-form-container .pay-summary-box .column {
      width: 100% !important;
      gap: 1rem;
    }
    .custom-form-container .pay-summary-box .summary-info.column .payment-info {
      gap: 0.5rem;
    }
  footer.footer-box {
    padding: 0 0.5rem !important;
    font-size: 10px !important; 
  }
    footer.footer-box .link-box .footer-link {
      padding: 0 !important;
    }
}

@media all and (max-width: 991px) {
  
}
.ugf-nav {
  height: 10%;
}

.ugf-nav .container {
  vertical-align: middle;
  height: 100%;
  max-width: 730px;
}
.ugf-nav .container .row {
  height: 100%;
}

.ugf-nav .navigation {
  min-height: 100%;
  min-width: 700px;
  max-width: 100%;
  align-content: center;
}

.navigation .nav-btns {
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-width: 70%;
  width: 100%;
  align-self: stretch;
  vertical-align: middle;
}

.navigation .nav-btns .prog-btn {
  width: 170px;
  height: 70px;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  background: var(--primary-50);
  color: rgba(255,255,255,0.5);
  border-radius: 8px;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  align-items: center;
}
 .navigation .nav-btns .prog-btn .txt {
  align-self:stretch;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center; 
 }

.navigation .nav-btns .prog-btn .prog-icon {
  margin-right: 10%;
}

.navigation .nav-btns .prog-btn.active {
  color: white;
  background-color: rgb(var(--secondary));
}
  .navigation .nav-btns .prog-btn.active .prog-icon {
    opacity: 1;
  }
  .navigation .nav-btns .prog-btn.completed .prog-icon{
    opacity: 1;
    padding-top: 2px;
  }
  .navigation .nav-btns .prog-btn.first {
    background: rgb(var(--primary));
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-transform: none;
  }

form h3,h2 {
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  color: rgb(var(--font-primary));
  margin-bottom: 10%;
}

form h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: rgb(var(--font-primary));
}

form h2 {
  font-size: 40px;
}

.ugf-form .btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10%;
  min-width: 370px;
}
  .ugf-form .btn-container .button {
    border-radius: 10px;
    max-width:33%;
    min-width: 33%;
    min-height: 100%;
    font-family: "Readex Pro";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 3% 0;
    text-align: center;
    cursor: pointer;
  }
    .ugf-form .btn-container .button.previous {
      border: 1px solid rgb(var(--font-primary));
      margin-right: auto;
    }
    .ugf-form .btn-container .button.getQuote {
      border: none;
      background: rgb(var(--secondary));
      color: white;
    }

.ugf-form {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  color: rgb(var(--font-primary));
  min-width: 370px;
}
[class *= "offset-"] {
  margin-left: auto;
  margin-right: auto;
}

form .descript-holder {
  min-width: 370px;
}

form .description-text {
  margin-bottom: 7%;
}

.input-block .selector-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border: 0.5px solid rgb(var(--font-secondary));
  min-width: 370px;
  border-radius: 8px;
  padding: 20px 10px;
  margin-bottom: 5%;
  position: relative;
}

.navigation .selector-holder {
  width: 100%;
}

.input-block .selector-container .selector-col {
  margin: 0 5px;
}
  .input-block .selector-container .selector-col .check-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: rgb(var(--font-primary));
    opacity: 0.7;
  }
  .input-block .selector-container .selector-col .check-content {
    font-weight: 300;
    font-size: 10px;
    line-height: 140%;
    color: rgb(var(--font-primary));
    opacity: 0.5;
  }

.input-block .selector-container .selector-col.right {
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  color: rgb(var(--font-primary));
  margin-right: 5%;
  margin-left: auto;
}

.input-block .selector-container .selector-col.middle {
  align-self: center;
}

.input-block .selector-container .selector-col.left {
  align-self: stretch;
  padding-top: 2px;
}

.check {
  cursor: pointer;
}

.form-group .input-label {
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: rgb(var(--font-primary));
}

.form-group .searchicon {
  float: right;
  margin-top: -32px;
  margin-right: 10px;
}

.input-block .form-group .questionIcon {
  margin-left: 10px;
}

.required:before {
  content: "* ";
  color:#B7240B;
}

a.push {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.radio-container, .radio-choice-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
  .radio-container {
    flex-direction: column;
    border: 0.5px solid rgb(var(--font-primary));
    border-radius: 8px;
  }
    .radio-choice-container {
      flex-direction: row;
      border-top: 0.5px solid rgb(var(--font-primary));
      padding: 5px 15px;
      align-items: center;
      align-content: center;
    }
      .radio-choice-container.first {
        flex-direction: row;
        border-top: none;
      }

.radio-container .radio-choice {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  color: #231F20;
  align-self: stretch;
  width: 100%;
  margin-top:10px;
}

.radio-container .radio-circle {
  margin-right: 10px;
}

.form-group input, select {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  color: #231F20 !important;
}
  .form-group option {
    color: rgba(35, 31, 32, 0.5);
  }

.ufg-main-container .custom-form-container {
  min-width: 100%;
  margin-top: 5%;
  margin-bottom: 2%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
  .ufg-main-container .custom-form-container .left-column {
    min-width: 30%;
    align-self: stretch;
  }
  .ufg-main-container .custom-form-container .right-column {
    align-self:stretch;
  }

.col-lg-12 form h3 {
  margin-bottom: 2%;
}

.widget-holder.form-shadow {
  background: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 0 3%;
}

.widget-container {
  min-width: 510px;
  width: 100%;
}
.widget-container .ugf-form {
  width: 100%;
}

form .descript-holder {
  min-width: 30%;
}

.pay-info-container {
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 6%;
}

.pay-info-container .pay-button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 500;
  text-align: center;
}

.pay-button-container .pay-button {
  width: 190px;
  height: 40px;
  text-align: center;
  border: 1px solid rgb(var(--font-secondary));
  border-radius: 10px;
  padding-top: 9px;
  margin-bottom: 20px;
  color: rgb(var(--font-secondary));
  cursor: pointer;
}

.pay-button-container .pay-button.selected {
  background: rgb(var(--secondary));
  border: none;
  border-radius: 10px;
  color: white;
}

.pay-button-container .pay-info-box {
  width: 270px;
  height: 110px;
  background: rgba(0, 178, 226, 0.2);
  border: 1px solid rgb(var(--font-secondary));
  border-radius: 10px;
  color: rgb(var(--font-secondary));
  margin-top: 10px;
  padding-top: 35px;
  padding-left: 15px;
  padding-right: 15px;
}

.right-column2 {
  max-width: 400px;
  position: relative;
}

@media all and (max-width: 1000px) {
  .custom-form-container {
    flex-wrap: wrap !important;
  }
}

.container .custom-row {
  justify-content: space-between;
}

.custom-row .right-column2 {
  max-width: 400px;
}
  .custom-row .embed-code {
    margin-top: 20%;
  }



input[type=text].error {
  border: 1px solid #B7240B !important;
}
  select.error {
    border: 1px solid #B7240B !important;
  }
  
form .error-message {
  font-size: 10px;
  line-height: 140%;
  color: #B7240B;
}

footer.footer-box {
  background: rgb(var(--background-color));
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 1% 10%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: rgb(var(--font-primary));
}
  footer.footer-box .link-box {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    min-width: 30%;
    justify-content: space-between;
  }
    footer.footer-box .link-box .footer-link{
      padding: 0 15px;
      font-weight: 500;
      text-decoration-line: underline;
    }

.widget-container .pay-choice-container {
  background: white;
  border-radius: 8px;
  min-height: 452px;
  padding: 20px 30px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content:space-between;
}
  .widget-container .pay-choice-container .back-button{
    margin: 20px 0px;
    margin-bottom: 0;
    padding: 5px 10px;
    width: 45%;
    height: 40px;
    border: 1px solid rgb(var(--font-secondary));
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: rgb(var(--font-secondary));
    padding-top: 10px;
    cursor: pointer;
  }
  .widget-container .pay-choice-container .pay-choices {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content:space-between;
  }
    .widget-container .pay-choice-container .pay-choices .pay-box {
      position: relative;
      width: 50%;
      margin: 2rem 0px;
    }
    .widget-container .pay-choice-container .pay-choices .pay-info-pop{
      min-width: 217px;
      min-height: 160px;
      font-weight: 300;
      font-size: 10px;
      line-height: 16px;
      color: var(--font-primary-50);
      background: #FFFFFF;
      text-transform: none;
      border: none;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      align-items: center;
      flex-direction: row;
      cursor: default;
    }
    .widget-container .pay-choice-container .pay-choices .pay-amount.pay-box {
      width: 25% !important;
      min-width: 20%;
    }

.pay-box .amount-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: rgb(var(--font-primary));
}
.pay-box .money-symbol {
  position: absolute;
  top: 31px;
  left: 10px;
  color: rgb(var(--font-primary));
  opacity: 0.5;
}
.pay-box .amount-input {
  font-family: "Readex Pro", sans-serif;
  border: 0.5px solid var(--font-primary-50) !important;
  border-radius: 8px;
  padding-left: 22px;
  width: 100%;
  overflow: hidden;
  height: 35px;
}
.pay-box .choice-box {
  min-width: 217px;
  min-height: 160px;
  background: rgb(var(--background-color));
  border: 0.5px solid var(--font-primary-50);
  border-radius: 8px;
  margin-top: 10px;
  opacity: 0.7;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: rgb(var(--font-primary));
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 15px 15px;
  cursor: pointer;
}
  .pay-box .choice-box.choice-card {
    margin-top: 35px;
  }
  .pay-box .choice-box.active {
    opacity: 1;
    border: 1px solid rgb(var(--font-secondary));
  }
  .pay-box .choice-box.inactive {
    opacity: 0.5;
  }
  .pay-box .choice-box.active .choice-box-title {
    color: rgb(var(--font-secondary));
  }
    .pay-box .choice-box .choice-box-content-holder {
      opacity: 0.7;
    }
    .pay-box .choice-box .choice-box-title {
      text-transform: uppercase;
      padding: 3px 5px;
      margin-bottom: 5px;
    }
    .pay-box .choice-box .choice-box-content-holder {
      display: flex;
      justify-content: stretch;
      flex-direction: column;
      flex-wrap: nowrap;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 17px;
      color: rgb(var(--font-primary));
      text-transform: none;
      align-self: stretch;
      min-height: 100%;
    }
      .pay-box .choice-box .choice-box-content-holder .line{
        border-bottom: 0.5px solid rgb(var(--font-primary));
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      .pay-box .choice-box .choice-box-content-holder .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      flex-direction: row;
      padding: 5px 8px;
      }
        .pay-box .choice-box .choice-box-content-holder .content .total-amount {
          color: rgb(var(--font-secondary));
        }
.form-shadow.height-padding {
  padding: 30px 20px;
  box-shadow: none;
  filter: none !important;
}
.form-spacer {
  margin-bottom: 30px;
  width: 100%;
}

.contact-radio-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  margin: 10px 2px;
}
  .contact-radio-container .choice-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    position: relative;
    cursor: pointer;
  }

  .contact-radio-container .choice-container .radio-label {
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    color: var(--font-primary-50);
    text-transform: none;
    cursor: pointer;
  }
  .contact-radio-container .choice-container .radio-box {
    width: 17px;
    height: 17px;
    cursor: pointer;
  }
    .btn.custom-pay-btn.drop-shadow {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
      font-size: 14px !important;
    }
.modal {
  display: none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
}

/* .modal-content {
  background: white;
  max-width: 425px;
  min-width: 400px;
  border-radius: 8px;
  margin: 10vh auto;
  padding: 20px;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  flex-wrap: nowrap;
} */

.modal-content .modal-confirm-text-box {
  text-align: center;
  height: 18%;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: var(--font-primary-50);
}
  .modal-content .modal-confirm-text-box h4{
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgb(var(--font-primary));
    margin-bottom: 10px;
    margin-top: 20px;
  }
.modal-content .modal-payment-amounts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 20%;
  margin: 20px auto;
  min-width: 300px;
}
  /* .modal-content .label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: rgb(var(--font-primary));
    padding: 5px 1px;
    margin-bottom: 5px;
  } */
    .modal-content .label .light-text {
      text-align: right;
      text-transform: none;
    }
    .modal-content .g-recaptcha {
      align-self: center;
    }
  .modal-content .content {
    margin-bottom: 10px;
  }
    .modal-content .modal-payment-amounts .line{
      border-bottom: 0.5px solid rgb(var(--font-secondary));
      width: 96%;
      margin: 5px 0;
      margin-left: 2%;
      margin-bottom: 15px;
    }
  .modal-content .light-text {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    color: rgb(var(--font-primary));
    opacity: 0.5;
  }
.modal-content .modal-info {
  height: 20%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 8px 20px;
  margin-top: 15px;
}
  .modal-content .modal-info .pillar {
    width:50%;
  }
.modal-content .modal-buttons {
  margin-top: 20px;
  height: 20%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
  .modal-content .modal-buttons .modal-btn {
    cursor: pointer;
    width: 176px;
    height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-radius: 8px;
    padding-top: 10px;
  }
  .modal-content .modal-buttons .back-button {
    border: 1px solid rgb(var(--font-secondary));
    color: rgb(var(--font-secondary));
  }
  .modal-content .modal-buttons .pay-button {
    background: rgb(var(--primary));
    color: white;
  }
.modal-content .custom-modal-footer {
  height: 14%;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: rgb(var(--font-primary));
  opacity: 0.5;
  margin-top: 5%;
}

.modal-content .custom-modal-footer .footer-link {
  text-decoration: underline;
  font-weight: 700;
}

.pay-choice-container.pay-summary-box {
  flex-direction: row;
}
.pay-choice-container .label {
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: rgb(var(--font-primary));
}
.pay-choice-container .light-text {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: rgb(var(--font-primary));
  opacity: 0.6;
}
.pay-choice-container .info-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: rgb(var(--font-primary));
}
.pay-choice-container .question-box .light-text {
  margin-top: 10px;
}
  .pay-choice-container.pay-summary-box .completed-info.column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 40%;
  }
  .pay-choice-container.pay-summary-box .completed-info .question-box {
    width: 240px;
    height: 103px;
    background: rgb(var(--background-color));
    border-radius: 8px; 
    padding: 10px 15px;
    line-height: 12px;
    display: flex;
    flex-direction: column;
  }
  .pay-choice-container.pay-summary-box .summary-info.column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 50%;
    line-height: 17px;
  }
    .pay-choice-container.pay-summary-box .summary-info h4.blue{
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: rgb(var(--font-secondary));
      width: 100%;
    }
    .pay-choice-container.pay-summary-box .summary-info .payment-info, .input-info, .purpose-info, .payment-method,
      .amount, .fee, .total {
      display: flex;
      flex-direction: row;
      justify-content : space-between;
    }
    .pay-choice-container.pay-summary-box .summary-info .line {
      border-bottom: 0.5px solid rgb(var(--font-secondary));
    }
      .pay-choice-container.pay-summary-box .summary-info .payment-info {
        flex-direction: column;
        min-height: 50%;
      }
      .pay-choice-container.pay-summary-box .summary-info .right {
        width: 45%;
      }
    .pay-choice-container.pay-summary-box .summary-info .label {
      margin-bottom: 10px;
    }  
.widget-container .terms-container {
  padding: 10px;
  align-self: stretch;
  overflow-y: scroll;
}
.widget-container .main-info-container {
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}
  .widget-container .main-info-container .buttons-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 15px;
    width: 100%;
  }
    .widget-container .main-info-container .buttons-holder .btn {
      width: 40%;
      height: 48px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: rgb(var(--font-secondary));
      padding-top: 13px;
    }
    .widget-container .main-info-container .buttons-holder .btn.proceed-button {
      background: rgb(var(--primary));
      border-radius: 8px;
      color: white;
    }
.pay-choice-container.pay-summary-box.main-info-container {
  min-width: 150px;
  min-height: 450px;
  height: 60vh;
}
.pay-choice-container .pay-calc-holder {
  display: flex;
  width: 70%;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: relative;
  align-items: flex-end;
  margin-bottom: 20px;
}
.pay-choice-container .pay-calc-holder .calc-part{
  width: 40%;
}
.pay-choice-container .pay-calc-holder .calc-button {
  background: rgb(var(--secondary));
  border-radius: 8px;
  height: 35px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: white;
  width: 50%;
  box-shadow: 0px 10px 20px rgba(0, 178, 226, 0.25);
  cursor: pointer;
}
.pay-choice-container .button-container {
  display: flex;
  justify-content: space-between;
}
.pay-choice-container .button-container .next-button {
  margin: 20px 0px;
  padding: 5px 10px;
  width: 45%;
  height: 40px;
  background: rgb(var(--primary));
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: white;
  padding-top: 10px;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}

.logo-text {
  font-weight: 900;
  font-size: 28px;
  color: rgb(var(--font-primary));
}
  .logo-text:hover {
    color: var(--font-primary-50);
  }

.progress-icon {
  font-size: 32px;
}
.check-circle {
  background-color: rgb(var(--primary));
}
.secondary-text {
  color: rgb(var(--font-secondary));
}