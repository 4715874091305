html, body {
    background-color: rgb(var(--background-color));
    margin: 0;
    font-size: 16px;
}
body {
    color: rgb(var(--font-primary));
    font-family: 'Readex Pro';
    line-height: 1.25;
}
html, body, .main-container, .payment-body {
    height: 100%;
}
a {
    text-decoration: none;
}
    a:hover {
        color: inherit;
    }
.pb-2r {
    padding-bottom: 2rem !important;
}
.main-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}
.payment-body {
    display: flex;
    flex-flow: column nowrap;
    padding: 2rem;
    padding-bottom: 0;
    width: 60%;
    min-width: 400px;
}
.payment-body .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2rem;
}
    .payment-body .header.nav {
        justify-content: center;
    }
    .payment-body .header .business-logo img {
        max-width: 300px;
        max-height: 150px;
    }
    .payment-body .header .business-name {
        display: block;
        font-size: 32px;
        font-weight: 900;
        margin: 0;
        text-align: center;
        max-width: 100%;
        text-overflow: clip;
        line-break: normal;
        word-break: break-all;
    }
    .payment-body .header .upper-right-details {
        display: flex;
        align-items: flex-end;
        flex-flow: column nowrap;
        text-transform: uppercase;
        gap: 0.5rem;
    }
    .payment-body .navigation {
        display: flex;
        justify-content: center;
        gap: 2rem;
        margin-bottom: 1rem;
    }
        .payment-body .navigation .prog-btn {
            height: 40px;
            font-size: 12px;
            font-weight: 700;
            line-height: 1rem;
            text-transform: uppercase;
            padding: 0 0.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            color: var(--font-primary-50);
            cursor: default;
        }
            .payment-body .navigation .prog-btn .progress-icon {
                font-size: 24px;
            }
            .payment-body .navigation .prog-btn.active {
                color: rgb(var(--font-primary));
                border-bottom:3px solid rgb(var(--font-secondary));
                cursor: pointer;
            }
            .payment-body .navigation .prog-btn.completed {
                color: rgb(var(--font-primary));
                cursor: pointer;
            }
.footer {
    align-self: flex-end;
    display: flex;
    gap: 3rem;
    padding: 1rem 0;
    align-items: center;
    font-weight: 300;
    font-size: 12px;
    line-height: 140%;
    margin-top: auto;
}
    .footer .links {
        display: flex;
        gap: 2rem;
    }
        .footer .links a {
            color: inherit;
            text-decoration: none;
        }
        .footer .links a:hover {
            color: rgb(var(--font-secondary));
        }
.payment-body .purpose-of-payment {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 2rem 0;
    margin-left: 2rem;
}
.subtitle {
    color: var(--font-primary);
    font-weight: 200;
    font-size: 18px;
    margin: 0.5rem 0;
    line-height: 1.25;
    letter-spacing: 1px;
}
.payment-select {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}
.select-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: var(--secondary-25);
    border-radius: 8px;
    height: 40px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}
    .select-header .paySelect {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;
        position: relative;
        z-index: 2;
        color: var(--font-primary);
        transition: color 0.5s;
    }
        .select-header .paySelect:hover {
            color: var(--font-primary-50);
        }
    .select-header .paySelect.select {
        color: var(--form-bg);
        border-radius: 8px;
        text-decoration: none;
    }
    #selector-box {
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        width: 50%;
        background-color:rgb(var(--secondary));
        border-radius: 8px;
        z-index:1;
        transition:left 0.5s;
    }
        #selector-box.left {
            left: 0%;
        }
.payment-form {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    align-items: stretch;
    background-color: var(--form-bg);
    padding: 1rem 2rem;
    border-radius: 8px;
}
    .payment-form.loading>* {
        visibility: hidden;
    }
    .payment-form .input-section {
        display: flex;
        flex-flow: column nowrap;
        position: relative;
    }
    .input-section .errorIcon {
        visibility: hidden;
        position: absolute;
        top: 13px;
        right: 8px;
    }
        .input-section.error .errorIcon {
            visibility: visible;
        }
    .input-section .eye-icon {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 11px;
        right: 8px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    label {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        line-height: 1rem;
        position: absolute;
        transition: top 0.5s, font-size 0.5s;
        top: 12px;
        left: 10px;
        border-radius: 6px;
        background-color: var(--form-bg) !important;
        width: auto !important;
        pointer-events: none;
        padding: 0 4px;
        letter-spacing: 1px;
    }
        label.animate {
            top: -6px;
            font-size: 10px;
        }
    label .required {
        color: var(--red);
        margin-right: 0.2rem;
    }
    span.label { /* This is for non-input labels (no animation) */
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        line-height: 1rem;
        width: auto !important;
        padding: 0 4px;
        letter-spacing: 1px;
    }
        .payment-form .input-section.error input {
            border-color: var(--red);
        }
        .payment-form .input-section input:focus {
            outline: none;
            border: 2px solid rgb(var(--primary));
        }
        .payment-form .input-section.error input:focus {
            border-color: var(--red);
        }
        .payment-form .input-section.error label {
            color: var(--red);
        }
    .payment-form .input-section input, .payment-form .input-section select {
        font-size: 12px;
        line-height: 16px;
        border-radius: 6px;
        border: 1px solid rgb(var(--primary));
        padding: 0.5rem 1rem;
        box-sizing:border-box;
        height: 40px;
    }
        .payment-form .input-section input::placeholder {
            visibility: hidden;
        }
    .payment-form .card-image {
        position: absolute;
        display: flex;
        gap: 0.25rem;
        right: 30px;
        top: 9px;
    }
    .payment-form .single-line {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }
        .single-line>div {
            flex: 1 1 50%;
            display: flex;
            flex-flow: column nowrap;
        }
        .single-line>.smaller {
            min-width: 175px;
            flex: 0 1 24%;
        }
        .single-line>.larger {
            flex: 1 1 76%;
        }
    .confirm-button {
        color: var(--form-bg);
        background-color: rgb(var(--primary));
        text-decoration: none;
        border: none;
        border-radius: 8px;
        width: 100%;
        min-width: 200px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
    }
        .confirm-button:hover {
            background-color: rgb(var(--secondary));
            color: var(--form-bg);
        }
        .confirm-button.disabled {
            pointer-events: none;
            background-color: var(--primary-50);
            opacity: 0.5;
        }
        .confirm-button.secondary {
            background-color: rgb(var(--secondary));
        }
            .confirm-button.secondary:hover {
                background-color: rgb(var(--primary));
            }
    .payment-form .errorMessage {
        color: var(--red);
        visibility: hidden;
        font-size: 10px;
        padding-left: 22px;
        padding-right:8px;
        align-self: flex-end;
    }
        .payment-form .input-section.error .errorMessage {
            visibility: visible;
        }
.payment-details, .personal-details, .billing-details {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    align-items: stretch;
}
#card-details, #ach-details {
    display: none;
}
#card-details {
    display: flex;
}
.eye-icon .closed-eye {
    display: none;
}
.price-section {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    max-width: 600px;
    width: 40%;
    min-width: 260px;
    max-width: 300px;
    margin: 0 0.5rem 0 auto !important;
    font-size: 14px;
    font-weight: 600;
    color: rgb(var(--font-primary));
    letter-spacing: 1px;
}
    .price-section .price-row {
        display: flex;
        justify-content: space-between;
    }
    .price-section label, .price-section span.label {
        font-size: 14px;
        color: var(--font-primary-50);
        font-weight: 400;
    }
.terms-container {
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.25;
}
.terms-section {
    display: flex;
    align-items: flex-start;
    margin: 1rem 0;
    gap: 1rem;
}
.terms {
    font-size: 10px;
    margin: 0;
    line-height:16px;
}
.terms b {
    font-weight: 700;
    font-size: 10px;
}
    .terms b.note {
        display: block;
        margin-top: 0.5rem;
    }
.disclaimer-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    gap: 1rem;
    font-size: 10px;
    font-weight: 400;
    background-color: var(--secondary-25);
}
    .info-icon {
        position: relative;
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
        background-color: rgb(var(--secondary));
        color: var(--form-bg);
        border-radius: 50%;
        font-size: 10px;
        font-weight: 600;
        pointer-events: none;
    }
        .info-icon.error {
            background-color: var(--red);
        }
        .info-icon .inner-icon {
            position: absolute;
            top: 2px;
            left: 5.5px;
            width: 3px;
            height: 10px;
            line-height: 10px;
        }
.payment-details .copy-name {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 10px;
}
    .payment-details .copy-name input {
        cursor: pointer;
    }
.custom-radio-container {
    display: flex;
    flex-flow: column nowrap;
}
    .custom-radio-container label {
        position: relative;
    }
    .custom-radio-container .custom-radio-row {
        display: flex;
        gap: 0.5rem;
        margin: 0.5rem 0;
    }
    .custom-radio-container .choice-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
input[type = 'radio'], input[type = 'checkbox'] {
    margin: 0;
    width: 16px;
    height: 16px;
}
.secondary-text {
    color: rgb(var(--font-secondary));
}
.confirm-button.half {
    width: 50%;
}
.confirm-button.smaller {
    width: 25%;
}
.confirm-button.right {
    margin-left: auto;
}
.input-section.prefix .input-prefix {
    position: absolute;
    left: 1rem;
    top: 12px;
    line-height: 16px;
    font-size: 16px;
    font-weight: 400;
}
    .input-section.prefix label {
        left: 2rem;
    }
    .input-section.prefix input {
        padding-left: 2rem;
    }
.payment-form .card-holder {
    display: flex;
    gap: 1rem;
    flex-flow: column nowrap;
    min-height: 205px;
}
    .card-holder .choice-card {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;
        background-color: rgb(var(--background-color));
        border: 1px solid var(--font-primary-50);
        border-radius: 8px;
        opacity: 0.75;
        font-weight: 300;
        font-size: 12px;
        line-height: 140%;
        color: rgb(var(--font-primary));
        padding: 1rem;
        cursor: pointer;
        flex: 1 1 50%;
        min-width: 205px;
    }
        .card-holder .choice-card.active {
            opacity: 1;
            border: 3px solid rgb(var(--secondary));
        }
    .card-holder .choice-card .card-column {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;
    }
        .card-holder .choice-card .card-column .card-row {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
        }
.line {
    border-bottom: 0.5px solid rgb(var(--font-primary));
    padding-bottom: .5rem;
    margin-bottom: .5rem;
}
    .line.secondary {
        border-color: rgb(var(--secondary));
    }
.payment-form .summary-column {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 300;
    line-height: 140%;
    flex: 1 1 50%;
}
    .summary-column .summary-row {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        font-size: 12px;
    }
        .summary-column .summary-row.bold * {
            font-size: 16px;
            font-weight: 600;
        }
        .summary-column .summary-row>span, .summary-column .summary-row>div {
            flex: 1 1 50%;
        }
    .summary-column.larger {
        flex: 1 1 70%;
    }
    .summary-column.smaller {
        flex: 1 1 30%;
    }
.payment-form .question-box {
    background-color: rgb(var(--background-color));
    border-radius: 8px;
    /* width: min-content; */
    display: flex;
    flex-flow: column nowrap;
    padding: 1rem;
    font-size: 12px;
    font-weight: 300;
    gap: 1rem;
}
#email-icon, #print-icon {
    cursor: pointer;
}
@media only screen and (max-width: 1500px) {
    .payment-body {
        width: 70%;
    }
}
@media only screen and (max-width: 1250px) {
    .payment-body {
        width: 80%;
    }
}
@media only screen and (max-width: 1100px) {
    .mobile-hide {
        display: none;
    }
    .payment-body .payment-link-form {
        padding: 0.5rem 1rem;
        margin-top: 0.5rem;
    }
    .payment-body .single-line {
        flex-flow: column nowrap;
        gap: 0.5rem;
    }
    .payment-body .payment-select h4 {
        font-size: 16px;
    }
    .payment-body .select-header {
        font-size: 12px;
    }
    .payment-body .disclaimer-section {
        align-items: center;
        gap: 0.5rem;
        padding: 0 0.5rem;
    }
    .payment-body .price-section {
        width: 70%;
    }
}
@media only screen and (max-width: 769px) {
    .payment-body {
        padding: 0.5rem;
        min-width: 359px;
    }
    .payment-body .header {
        flex-flow: column nowrap;
        gap: 1rem;
        align-items: center;
        margin: 1rem 0;
    }
    .payment-body .header .upper-right-details {
        align-items: center;
        font-size: 16px;
        align-self: center;
    }
    .payment-body .purpose-of-payment {
        text-align: center;
        margin: 0.5rem 0;
        font-size: 16px;
    }
    .card-image {
        visibility: hidden;
    }
    .footer {
        gap: 1rem;
        padding: 0.5rem 0;
        font-size: 10px;
    }
    .input-section {
        flex: 1 1 100%;
    }
    .confirm-button.half, .confirm-button.smaller, .confirm-button.right {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        min-width: 100px;
    }
    .mobile-wrap {
        flex-wrap: wrap;
    }
    .summary-column .question-box {
        margin-top: 1rem !important;
        align-self: flex-start !important;
    }
    .pb-2r {
        padding-bottom: 1rem !important;
    }
}
@media only screen and (max-width: 550px) {
    .payment-body .navigation {
        gap: 0.5rem;
    }
        .payment-body .navigation .prog-btn {
            gap: 0.5rem;
        }
    .price-section {
        width: auto !important;
        margin: 0 !important;
        max-width: unset;
    }
        .price-section .price-row {
            justify-content: space-between;
        }
}